body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  /* background-color: #fff; */
}

.ant-input {
  border-radius: 3px;
}

.ant-picker {
  border-radius: 3px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 3px;
  width: 99%;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 3px;
}

.ant-input-affix-wrapper {
  border-radius: 3px;
}

.ant-input-search-button {
  background-color:  #fff;
  color: #32ab6d;
}

.ant-select {
  border-radius: 3px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
}

.ant-input-group-addon {
  border-radius: 3px;
}

.ant-card-actions {
  border-radius: 3px;
}

.ant-input-group-addon 
  .ant-select.ant-select-single:not(.ant-select-customize-input) 
    .ant-select-selector {
      border-radius: 3px 0 0 6px;
    }

.ant-form-item-explain-error {
  font-size: 11px;
}

.logo {
  float: left;
  width: 140px;
  height: 46px;
  margin: 9px;
}

.logo-favicon {
  width: 40px;
  height: 40px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

@media only screen and (min-width: 1200px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfdfdf;
    height: 80px;
    padding-left: 140px;
    padding-right: 160px;
  }
}

@media only screen and (min-width: 992px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfdfdf;
    height: 80px;
    padding-left: 70px;
    padding-right: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfdfdf;
    height: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfdfdf;
    height: 80px;
  }
}

.header-mobile {
  padding: 0;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.ant-list {
  background: #fff;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.menu-mobile {
  color: black;
}

.menu-mobile-selected {
  color: #32ab6d;
}

.button-facebook {
  border-radius: 3px;
}

.button-google {
  border-radius: 3px;
}

.button-facebook:hover {
  color: #1877f2 !important;
  border: 1px solid #1877f2;
}

.button-google:hover {
  color: #ea4335 !important;
  border: 1px solid#ea4335;
}

.loading-style {
  height: 100vh;
  text-align: center;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.box-shadow {
  box-shadow: 1px 1px 8px #cccccc;
}

.width-auto {
  width: auto;
}

.bg-color {
  color: #fafafa !important;
}
.txt-color {
  color: #848484 !important;
}

.drk-color {
  color: #5f6163 !important;
}

.a-color {
  color: #0076de !important;
}

.primary-color {
  color: #32ab6d !important;
}

.dgr-color {
  color: #dc5f5f !important;
}

.dis-none {
  display: none !important;
}

.dis-flex {
  display: flex;
}

.dis-inline {
  display: inline;
}

.f-right {
  float: right !important;
}

.f-left {
  float: left;
}

.txt-algn-center {
  text-align: center !important;
}

.txt-algn-left {
  text-align: left !important;
}

.br-3 {
  border-radius: 3px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.pad-16 {
  padding: 16px;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-64 {
  padding-top: 64px;
}

.pr-4 {
  padding-right: 4px;
}


.pr-8 {
  padding-right: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-4 {
  padding-left: 4px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-32 {
  padding-left: 32px;
}

.m-16 {
  margin: 16px;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-min-16 {
  margin-bottom: -16px !important;
}

.mb-min-8 {
  margin-bottom: -8px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 4px;
}

.fw-normal {
  font-weight: normal !important;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fst-i {
  font-style: italic;
}

.c-pointer {
  cursor: pointer;
}
.icon-md {
  font-size: medium;
}

.sidebar {
  position: absolute;
  display: none;
}

.m-item {
  padding: 0 !important;
  background-color: transparent !important;
}

.web-company:hover {
  color: #0076de !important;
}

@media only screen and (max-width: 575px) {
  .card-forgot-password {
    margin: 10px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 576px) {
  .card-forgot-password {
    margin: 80px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 768px) {
  .card-forgot-password {
    margin: 120px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 840px) {
  .breadcrumb-terms {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 16px;
  }

  .card-terms {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 800px;
    padding: 50px 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 841px) {
  .breadcrumb-terms {
    margin-top: 60px;
    padding-bottom: 16px;
  }

  .card-terms {
    max-width: 800px;
    padding: 50px 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

.ant-progress-text {
  margin-left: 6px !important;
}

.bridge:hover {
  cursor: pointer;
  box-shadow: 1px 1px 8px #cccccc;
}