@media only screen and (max-width: 575px) {
  .card-login {
    margin: 10px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 576px) {
  .card-login {
    margin: 80px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 768px) {
  .card-login {
    margin: 120px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

