/* .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
    padding: 0;
} */

.user-name {
  display: flex; 
  justify-content: center;
  align-items: center;
}

.not-menu:hover {
  background: transparent !important;
  cursor: default !important;
}