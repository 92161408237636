@media only screen and (max-width: 575px) {
  .card-register {
    margin: 10px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }

  .prefix-selector {
    width: 125px !important;
  }
}

@media only screen and (min-width: 576px) {
  .card-register {
    margin: 80px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .prefix-selector {
    width: 150px !important;
  }
}

@media only screen and (min-width: 768px) {
  .card-register {
    margin: 120px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .prefix-selector {
    width: 150px !important;
  }
}

