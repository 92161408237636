.ant-carousel .landing-page .slick-dots li button {
  margin-top: -50px;
}

@media only screen and (max-width: 575px) {
  .title-header-companies {
    text-align: center;
    margin-top: -20px;
    padding-bottom: 16px;
  }
}

@media only screen and (min-width: 576px) {
  .title-header-companies {
    text-align: center;
    margin-top: 40px;
    padding-bottom: 16px;
  }
}

.title-content-2 {
  text-align: center;
  padding: 32px 0 0 0;
  font-weight: normal;
  color: #3D464D;
}

.title-job:hover {
  color: #32ab6d !important;
  text-decoration: underline !important;
  transition: 0.5s;
}

.company-job:hover {
  color: #32ab6d !important;
  transition: 0.5s;
}

@media only screen and (max-width: 991px) {
  .featured-jobs {
    margin-bottom: 64px;
  }

  .title-content-1 {
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  .title-content-1 {
    text-align: center;
    padding: 32px 0 0 0;
  }
}
